<template>
  <div>
    <v-row class="mb-4">
      <v-col>
        <div class="d-flex align-center justify-space-between">
          <h2>{{ $t('job_post.job_detail') }}</h2>
        </div>
      </v-col>
    </v-row>

    <!-- Job Detail -->
    <v-form ref="job_form">
      <v-card elevation="0" class="px-6 py-4 mb-8">
        <v-row>
          <v-col>
            <h3>{{ $t('job_post.job_detail') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.job_id')"
              outlined
              readonly
              filled
              hide-details
              v-model="job_data.id"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.create_date')"
              outlined
              readonly
              filled
              hide-details
              v-model="job_data.create_date"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="d-flex align-center">
            <v-text-field
              type="text"
              :label="$t('job_post.customer_id')"
              outlined
              filled
              readonly
              hide-details
              v-model="customer_data.id"
            ></v-text-field>
            <router-link :to="{ name: 'CustomerDetail', params: { id: customer_data.id } }" class="ml-4">
              <v-btn color="info" depressed small>
                {{ $t('job_post.view_customer') }}
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.customer_first_name')"
              outlined
              readonly
              filled
              hide-details
              v-model="customer_data.first_name"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.customer_last_name')"
              outlined
              readonly
              filled
              hide-details
              v-model="customer_data.last_name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="12" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.job_title')"
              outlined
              hide-details
              v-model="job_data.job_title"
              :rules="[FormValidator.required]"
            ></v-text-field>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="12" cols="12">
            <v-select
              :label="$t('job_post.job_type')"
              :items="job_type_list"
              item-text="item-text"
              item-value="item-value"
              hide-details="auto"
              outlined
              v-model="job_data.job_type"
              :readonly="job_data.job_type_disabled"
              :filled="job_data.job_type_disabled"
              :rules="[FormValidator.required]"
            ></v-select>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="12" cols="12">
            <v-checkbox
              v-model="job_data.private"
              hide-details
              color="primary"
              :label="$t('job_type.private')"
              value
              input-value="true"
              @change="privateChecked"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="12" cols="12">
            <v-menu
              v-model="start_date_modal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="job_start_date"
                  :label="$t('worker.start_date')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  v-bind="attrs"
                  :rules="[FormValidator.required]"
                  :error-messages="date_error"
                  v-on="on"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker v-model="job_start_date" @input="start_date_modal = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="12" cols="12">
            <v-menu
              v-model="end_date_modal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="job_end_date"
                  :label="$t('worker.end_date')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  :rules="[FormValidator.required]"
                  :error-messages="date_error"
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker v-model="job_end_date" @input="end_date_modal = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="12" cols="12">
            <v-select
              :label="$t('general.time')"
              :items="time_list"
              item-text="item-text"
              item-value="item-value"
              hide-details="auto"
              :rules="[FormValidator.required]"
              outlined
              v-model="job_data.time"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('job_post.estimated_hours_per_week')"
              :items="estimated_hour_list"
              item-text="item-text"
              item-value="item-value"
              hide-details
              outlined
              :rules="[FormValidator.required]"
              v-model="job_data.estimated_hours"
            ></v-select>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('worker.location')"
              :items="location_list"
              item-text="item-text"
              item-value="item-value"
              hide-details
              outlined
              :rules="[FormValidator.required]"
              v-model="job_data.location"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              :rules="[FormValidator.required]"
              hide-details="auto"
              rows="4"
              :label="$t('job_post.support_person_need_to_do')"
              v-model="job_data.support_person_do"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-textarea
              outlined
              hide-details
              rows="4"
              :label="$t('job_post.important_to_know')"
              v-model="job_data.important_for_support"
            ></v-textarea>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-textarea
              outlined
              hide-details
              rows="4"
              :label="$t('job_post.worker_preference')"
              v-model="job_data.worker_prefer"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('job_post.gender_preference')"
              :items="gender_preference_list"
              item-text="item-text"
              item-value="item-value"
              hide-details
              outlined
              :rules="[FormValidator.required]"
              v-model="job_data.gender_prefer"
            ></v-select>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('job_post.status')"
              :items="job_status_list"
              item-text="item-text"
              item-value="item-value"
              :rules="[FormValidator.required]"
              hide-details
              outlined
              v-model="job_data.status"
            ></v-select
          ></v-col>
        </v-row>

        <v-row>
          <v-col>
            <h4>{{ $t('worker.services') }}</h4>
          </v-col>
        </v-row>

        <!-- Social Support -->
        <v-row v-ripple @click="service_collapse_list.social_support = !service_collapse_list.social_support">
          <v-col class="d-flex align-center justify-space-between expansion-card">
            <h4>{{ $t('worker.social_support') }}</h4>
            <v-icon v-if="service_collapse_list.social_support">{{ icon.expand_icon }}</v-icon>
            <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-show="!service_collapse_list.social_support">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="(social_support, index) in service_social_support_field"
              :key="index"
            >
              <v-checkbox
                hide-details
                dense
                color="primary"
                :label="$t(social_support.text)"
                :value="social_support.value"
                v-model="service_model.social_support"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expand-transition>

        <!-- Personal Care -->
        <v-row v-ripple @click="service_collapse_list.personal_care = !service_collapse_list.personal_care">
          <v-col class="d-flex align-center justify-space-between expansion-card">
            <h4>{{ $t('worker.personal_care') }}</h4>
            <v-icon v-if="service_collapse_list.personal_care">{{ icon.expand_icon }}</v-icon>
            <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-show="!service_collapse_list.personal_care">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="(personal_care, index) in service_personal_care_field"
              :key="index"
            >
              <v-checkbox
                hide-details
                dense
                color="primary"
                :label="$t(personal_care.text)"
                :value="personal_care.value"
                v-model="service_model.personal_care"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expand-transition>

        <!-- Nursing Service -->
        <v-row v-ripple @click="service_collapse_list.nursing_service = !service_collapse_list.nursing_service">
          <v-col class="d-flex align-center justify-space-between expansion-card">
            <h4>{{ $t('job_post.nursing_service') }}</h4>
            <v-icon v-if="service_collapse_list.nursing_service">{{ icon.expand_icon }}</v-icon>
            <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-show="!service_collapse_list.nursing_service">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="(nursing_service, index) in service_nursing_service_field"
              :key="index"
            >
              <v-checkbox
                hide-details
                dense
                color="primary"
                :label="$t(nursing_service.text)"
                :value="nursing_service.value"
                v-model="service_model.nursing"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expand-transition>

        <!-- Allied-health Service -->
        <v-row v-ripple @click="service_collapse_list.allied_health = !service_collapse_list.allied_health">
          <v-col class="d-flex align-center justify-space-between expansion-card">
            <h4>{{ $t('worker.allied_health') }}</h4>
            <v-icon v-if="service_collapse_list.allied_health">{{ icon.expand_icon }}</v-icon>
            <v-icon v-else>{{ icon.collapse_icon }}</v-icon>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-show="!service_collapse_list.allied_health">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="(allied_health, index) in service_allied_health_field"
              :key="index"
            >
              <v-checkbox
                hide-details
                dense
                color="primary"
                :label="$t(allied_health.text)"
                :value="allied_health.value"
                v-model="service_model.allied"
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expand-transition>

        <!-- Participants -->
        <v-row>
          <v-col>
            <h4>{{ $t('job_post.participants') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="6" sm="12" cols="12" class="d-flex align-center">
            <v-select
              :items="participantList"
              item-text="item-text"
              item-value="item-value"
              outlined
              dense
              hide-details="auto"
              v-model="selectParticipantId"
              :error="selectParticipantError"
            ></v-select>
            <v-btn color="info" @click="addParticipants()" :loading="submitLoading" small class="ml-4">{{
              $t('job_post.add_participants')
            }}</v-btn>
          </v-col>
        </v-row>
        <div v-for="(participant, index) in selectParticipantList" :key="index">
          <v-row>
            <v-col>
              <div class="d-flex align-center">
                <h4>
                  {{ $t('customer.participant_detail') }}
                  {{ job_data.participants.length > 1 ? index + 1 : '' }}
                </h4>
                <v-btn
                  icon
                  color="error"
                  class="ml-4"
                  @click="removeParticipants(index)"
                  v-if="selectParticipantList.length > 1"
                >
                  <v-icon>{{ icon.delete_icon }}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                label="ID"
                outlined
                v-model="participant.id"
                hide-details="auto"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-checkbox
                v-model="participant.same_as_ac"
                hide-details
                dense
                color="primary"
                :label="$t('customer.same_as_ac')"
                value
                input-value="true"
                readonly
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                :label="$t('worker.first_name')"
                outlined
                v-model="participant.first_name"
                hide-details="auto"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                :label="$t('worker.last_name')"
                outlined
                v-model="participant.last_name"
                readonly
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                :label="$t('worker.gender')"
                outlined
                v-model="participant.gender"
                readonly
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                :label="$t('job_post.age')"
                outlined
                :value="globalFunc.getAge(participant.birthday)"
                readonly
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                :label="$t('worker.email')"
                outlined
                v-model="participant.email"
                hide-details="auto"
                readonly
                filled
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="4" cols="12">
              <v-text-field
                type="text"
                :label="$t('worker.mobile_number')"
                outlined
                v-model="participant.mobile"
                readonly
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-12">
          <v-col>
            <v-btn color="primary" @click="saveJob()" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <!-- Payment Detail -->
    <v-card elevation="0" class="px-6 py-4 mb-8" v-if="show.payment">
      <v-row>
        <v-col>
          <h3>{{ $t('job_post.payment_detail') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('job_post.payment_status')"
            :items="paymentStatusList"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="payment_data.status"
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <div class="d-flex align-center">
            <v-text-field
              type="text"
              :label="$t('job_post.payment_method')"
              outlined
              readonly
              filled
              hide-details
              v-model="payment_data.payment_method_text"
            ></v-text-field>
            <v-btn
              v-if="payment_data.payment_method == 'offline'"
              class="ml-2"
              color="primary"
              small
              @click="viewRecord(payment_data.file)"
              >{{ $t('general.view_record') }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col>
          <v-btn color="primary" @click="savePayment()" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- Agreement -->
    <v-form ref="agreement_form">
      <v-card elevation="0" class="px-6 py-4 mb-8" v-if="show.agreement">
        <v-row>
          <v-col>
            <h3>{{ $t('job_post.agreement') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.create_date')"
              outlined
              readonly
              hide-details
              filled
              v-model="agreement_data.create_date"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <div class="d-flex align-center">
              <v-text-field
                type="text"
                :label="$t('job_post.worker_id')"
                outlined
                readonly
                hide-details
                filled
                v-model="agreement_data.worker_id"
              ></v-text-field>
              <router-link :to="{ name: 'WorkerDetail', params: { id: agreement_data.worker_id } }" class="ml-2">
                <v-btn color="info" depressed small>
                  {{ $t('job_post.view_worker') }}
                </v-btn>
              </router-link>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.worker_first_name')"
              outlined
              readonly
              filled
              v-model="agreement_data.first_name"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.worker_last_name')"
              filled
              v-model="agreement_data.last_name"
              outlined
              readonly
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="12" lg="12" md="12" sm="12" cols="12">
            <v-textarea
              outlined
              hide-details="auto"
              rows="4"
              :label="$t('job_post.agreed_service_description')"
              v-model="agreement_data.service_description"
              :rules="[FormValidator.required]"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('job_post.total_amount')"
              outlined
              readonly
              hide-details
              filled
              v-model="agreement_data.total"
              prefix="$"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('job_post.agreement_status')"
              :items="agreementStatusList"
              item-text="item-text"
              item-value="item-value"
              hide-details="auto"
              v-model="agreement_data.status"
              :rules="[FormValidator.required]"
              outlined
              :readonly="agreement_data.status === 'timesheetCompleted'"
              :filled="agreement_data.status === 'timesheetCompleted'"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h4>{{ $t('job_post.agreed_rate') }}</h4>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col>
            <CorrectDataTable
              :table-headers="agreed_rate_header"
              :table-data="agreed_rate_table_list"
              :page-limit="agreed_rate_table.tablePageLimit"
              :page-limit-options="agreed_rate_table.tablePageLimitOptions"
              :item-total="agreed_rate_table.total_items"
              :is-loading="agreed_rate_table.load_table"
              :enable-search="false"
              :enable-filter="false"
              :enable-date-range="false"
              :enable-clear-all="false"
              :disable-pagination="agreed_rate_table.load_table"
              :disable-sort="true"
              @options-update="onAgreedRateTableChange"
            ></CorrectDataTable>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <v-btn color="primary" @click="saveAgreement()" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <!-- Timesheet Detail -->
    <v-card elevation="0" class="px-6 py-4 mb-8" v-if="show.timesheet">
      <v-row>
        <v-col>
          <h3>{{ $t('job_post.timesheet_detail') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('job_post.timesheet_status')"
            :items="timesheetStatusList"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
            v-model="timesheet_data.status"
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-menu
            v-model="payroll_paid_date_modal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="payroll_paid_date"
                :label="$t('job_post.payroll_paid_date')"
                :append-icon="icon.calendar_icon"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="payroll_paid_date" @input="payroll_paid_date_modal = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="12" lg="12" md="12" sm="12" cols="12">
          <v-textarea
            outlined
            hide-details
            rows="4"
            :label="$t('job_post.shift_note')"
            v-model="timesheet_data.shift_note"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <CorrectDataTable
            :table-headers="agreed_rate_header"
            :table-data="timesheet_rate_table_list"
            :page-limit="timesheet_rate_table.tablePageLimit"
            :page-limit-options="timesheet_rate_table.tablePageLimitOptions"
            :item-total="timesheet_rate_table.total_items"
            :is-loading="timesheet_rate_table.load_table"
            :enable-search="false"
            :enable-filter="false"
            :enable-date-range="false"
            :enable-clear-all="false"
            :disable-pagination="timesheet_rate_table.load_table"
            :disable-sort="true"
            @options-update="onTimesheetRateTableChange"
          ></CorrectDataTable>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          <v-btn color="primary" @click="saveTimesheet()" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { mdiCalendar, mdiDelete, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { FormValidator, CheckInputValidate, globalFunc, MEDIA_PATH } from '@/global'
import { getData, postData } from '@/api'
export default {
  name: 'JobPostDetail',
  mixins: [FormValidator, CheckInputValidate, globalFunc],
  components: {
    CorrectDataTable,
  },
  data: () => ({
    globalFunc,
    FormValidator,
    submitLoading: false,
    icon: {
      calendar_icon: mdiCalendar,
      expand_icon: mdiChevronDown,
      collapse_icon: mdiChevronUp,
      delete_icon: mdiDelete,
    },
    job_data: {},
    participantList: [],
    selectParticipantList: [],
    selectParticipantId: '',
    selectParticipantError: false,
    customer_data: {
      id: -1,
    },
    agreement_data: {
      worker_id: -1,
    },
    start_date_modal: false,
    job_start_date: '',
    end_date_modal: false,
    job_end_date: '',
    date_error: '',
    location_list: [],
    job_type_list: [],
    time_list: [],
    estimated_hour_list: [],
    job_status_list: [],
    gender_preference_list: [],
    service_collapse_list: {
      social_support: true,
      personal_care: true,
      nursing_service: true,
      allied_health: true,
    },
    service_social_support_field: [],
    service_personal_care_field: [],
    service_nursing_service_field: [],
    service_allied_health_field: [],
    service_model: {
      social_support: [],
      personal_care: [],
      nursing: [],
      allied: [],
    },
    show: {
      payment: false,
      agreement: false,
      timesheet: false,
    },
    paymentStatusList: [],
    payment_data: {},
    agreementStatusList: [],
    agreed_rate_header: [],
    agreed_rate_table_list: [],
    store_agreed_rate_table: [],
    agreed_rate_table: {
      tablePage: 1,
      tablePageLimit: 10,
      tablePageLimitOptions: [10, 20, 50],
      total_items: 0,
      load_table: true,
    },
    timesheet_rate_table_list: [],
    store_timesheet_rate_table: [],
    timesheet_rate_table: {
      tablePage: 1,
      tablePageLimit: 10,
      tablePageLimitOptions: [10, 20, 50],
      total_items: 0,
      load_table: true,
    },
    timesheetStatusList: [],
    timesheet_data: {},
    payroll_paid_date_modal: false,
    payroll_paid_date: '',
  }),
  methods: {
    initializeStatus() {
      this.location_list = [
        { 'item-text': this.$t('location.central'), 'item-value': 'Central and Western' },
        { 'item-text': this.$t('location.east'), 'item-value': 'Eastern' },
        { 'item-text': this.$t('location.south'), 'item-value': 'Southern' },
        { 'item-text': this.$t('location.wc'), 'item-value': 'Wan Chai' },
        { 'item-text': this.$t('location.klc'), 'item-value': 'Kowloon City' },
        { 'item-text': this.$t('location.kt'), 'item-value': 'Kwun Tong' },
        { 'item-text': this.$t('location.ssp'), 'item-value': 'Sham Shui Po' },
        { 'item-text': this.$t('location.wts'), 'item-value': 'Wong Tai Sin' },
        { 'item-text': this.$t('location.ysm'), 'item-value': 'Yau Tsim Mong' },
        { 'item-text': this.$t('location.island'), 'item-value': 'Islands' },
        { 'item-text': this.$t('location.kc'), 'item-value': 'Kwai Tsing' },
        { 'item-text': this.$t('location.north'), 'item-value': 'North' },
        { 'item-text': this.$t('location.sk'), 'item-value': 'Sai Kung' },
        { 'item-text': this.$t('location.st'), 'item-value': 'Sha Tin' },
        { 'item-text': this.$t('location.tp'), 'item-value': 'Tai Po' },
        { 'item-text': this.$t('location.tw'), 'item-value': 'Tsuen Wan' },
        { 'item-text': this.$t('location.tm'), 'item-value': 'Tuen Mun' },
        { 'item-text': this.$t('location.yl'), 'item-value': 'Yuen Long' },
      ]

      this.time_list = [
        { 'item-text': this.$t('time_list.morning'), 'item-value': 'morning' },
        { 'item-text': this.$t('time_list.afternoon'), 'item-value': 'afternoon' },
        { 'item-text': this.$t('time_list.evening'), 'item-value': 'evening' },
        { 'item-text': this.$t('time_list.overnight'), 'item-value': 'overnight' },
      ]

      this.estimated_hour_list = [
        { 'item-text': this.$t('estimated_hour_list.less_than_five'), 'item-value': 'Less than 5' },
        { 'item-text': this.$t('estimated_hour_list.five'), 'item-value': '5-10' },
        { 'item-text': this.$t('estimated_hour_list.ten'), 'item-value': '10-20' },
        { 'item-text': this.$t('estimated_hour_list.twenty'), 'item-value': 'More than 20' },
      ]

      this.gender_preference_list = [
        { 'item-text': this.$t('gender_preference.male'), 'item-value': 'Male only' },
        { 'item-text': this.$t('gender_preference.female'), 'item-value': 'Female Only' },
        { 'item-text': this.$t('gender_preference.no_preference'), 'item-value': 'No Preferences' },
      ]

      this.job_type_list = [
        { 'item-text': this.$t('job_type.last_minute'), 'item-value': 'last_minute' },
        { 'item-text': this.$t('job_type.standard'), 'item-value': 'standard' },
      ]

      this.job_status_list = [
        { 'item-text': this.$t('status.active'), 'item-value': 'active' },
        { 'item-text': this.$t('status.inactive'), 'item-value': 'inactive' },
        { 'item-text': this.$t('status.filled'), 'item-value': 'filled' },
        { 'item-text': this.$t('status.canceled'), 'item-value': 'canceled' },
      ]

      this.paymentStatusList = [
        { 'item-text': this.$t('status.pending'), 'item-value': 'pending' },
        { 'item-text': this.$t('status.paid'), 'item-value': 'paid' },
        { 'item-text': this.$t('status.canceled'), 'item-value': 'canceled' },
      ]

      this.agreementStatusList = [
        { 'item-text': this.$t('status.pending'), 'item-value': 'pending' },
        { 'item-text': this.$t('status.active'), 'item-value': 'completed' }, // backend: completed === frontend: active
        { 'item-text': this.$t('status.canceled'), 'item-value': 'canceled' },
        { 'item-text': this.$t('status.decline'), 'item-value': 'decline' },
        { 'item-text': this.$t('status.completed'), 'item-value': 'timesheetCompleted' }, // when timesheet status = completed
      ]

      this.timesheetStatusList = [
        { 'item-text': this.$t('status.pending'), 'item-value': 'pending' },
        { 'item-text': this.$t('status.completed'), 'item-value': 'approved' }, // backend:approved === frontend:completed
        { 'item-text': this.$t('status.decline'), 'item-value': 'unapproved' }, // backend:unapproved === frontend:decline
      ]
    },
    setFieldData(text, value) {
      let obj = {
        text: text,
        value: value,
      }

      return obj
    },
    initializeServiceField() {
      this.service_social_support_field = [
        this.setFieldData(
          'service_social_support_field.activities_outings_community_access',
          'Activities, Outings & Community Access',
        ),
        this.setFieldData('service_social_support_field.home_maintenance', 'Home Maintenance'),
        this.setFieldData('service_social_support_field.light_housework', 'Light Housework'),
        this.setFieldData('service_social_support_field.meal_delivery', 'Meal Delivery'),
        this.setFieldData('service_social_support_field.meal_preparation', 'Meal Preparation'),
        this.setFieldData('service_social_support_field.personal_assistant', 'Personal Assistant (Admin)'),
        this.setFieldData('service_social_support_field.provide_transport', 'Provide Transport'),
        this.setFieldData('service_social_support_field.shopping', 'Shopping'),
        this.setFieldData('service_social_support_field.sports_exercise', 'Sports & Exercise'),
        this.setFieldData('service_social_support_field.work_experience', 'Work Experience'),
      ]

      this.service_personal_care_field = [
        this.setFieldData('service_personal_care_field.assistance_with_eating', 'Assistance with Eating'),
        this.setFieldData('service_personal_care_field.assist_with_medication', 'Assist with Medication'),
        this.setFieldData('service_personal_care_field.care_assessment', 'Care Assessment, Planning, Coordination'),
        this.setFieldData('service_personal_care_field.exercise_assistance', 'Exercise Assistance'),
        this.setFieldData('service_personal_care_field.hoist_transfer', 'Hoist & Transfer'),
        this.setFieldData('service_personal_care_field.light_massage', 'Light Massage'),
        this.setFieldData('service_personal_care_field.manual_transfer_and_mobility', 'Manual Transfer and Mobility'),
        this.setFieldData('service_personal_care_field.palliative_care', 'Palliative Care'),
        this.setFieldData('service_personal_care_field.showering_dressing_grooming', 'Showering, Dressing, Grooming'),
        this.setFieldData('service_personal_care_field.toileting', 'Toileting'),
      ]

      this.service_nursing_service_field = [
        this.setFieldData('nursing_registration_field.conduction_health_assessments', 'Conducting health assessments'),
        this.setFieldData('nursing_registration_field.medication_management', 'Medication management'),
        this.setFieldData('nursing_registration_field.chronic_disease_monitoring', 'Chronic disease monitoring'),
        this.setFieldData('nursing_registration_field.pain_management', 'Pain management'),
        this.setFieldData('nursing_registration_field.oxygen_therapy', 'Oxygen therapy'),
        this.setFieldData('nursing_registration_field.post_operative_recovery', 'Post-operative recovery'),
      ]

      this.service_allied_health_field = [
        this.setFieldData('allied_health_field.occupational_therapy', 'Occupational Therapy'),
        this.setFieldData('allied_health_field.physiotherapy', 'Physiotherapy'),
        this.setFieldData('allied_health_field.psychological_services', 'Psychological Services'),
        this.setFieldData('allied_health_field.speech_therapy', 'Speech Therapy'),
        this.setFieldData('allied_health_field.social_work', 'Social Work'),
      ]
    },
    initializeHeader() {
      this.agreed_rate_header = [
        {
          text: this.$t('job_post.working_dates'),
          value: 'working_dates',
          width: '300px',
        },
        {
          text: this.$t('job_post.start_time'),
          value: 'start_time',
        },
        {
          text: this.$t('job_post.end_time'),
          value: 'end_time',
        },
        {
          text: this.$t('job_post.hourly_flat'),
          value: 'hourly_flat',
        },
        {
          text: this.$t('job_post.total_hours'),
          value: 'total_hours',
        },
        {
          text: this.$t('job_post.hourly_rate'),
          value: 'hourly_rate',
        },
        {
          text: this.$t('job_post.flat_rate'),
          value: 'flat_rate',
        },
      ]
    },
    async initializeJobData() {
      try {
        let params = new URLSearchParams()
        params.set('get_job_by_id', JSON.stringify({ id: parseInt(this.$route.params.id, 10) }))
        let get_job_data = await getData(params)
        this.$func.log('-----Get Job Data-----')
        this.$func.log(get_job_data)

        if (!Array.isArray(get_job_data)) {
          this.getUserData(
            get_job_data.user_id,
            'customer',
            globalFunc.DataValid(get_job_data.participants) ? get_job_data.participants : [],
          )

          this.job_data = {
            id: get_job_data.id,
            create_date: globalFunc.getDateStr(get_job_data.create_date),
            job_title: get_job_data.job_title,
            job_type: get_job_data.job_type,
            private: get_job_data.private,
            location: get_job_data.location,
            time: get_job_data.time_string,
            estimated_hours: get_job_data.estimated_hours,
            support_person_do: get_job_data.support_person_do,
            gender_prefer: get_job_data.gender_prefer,
            worker_prefer: get_job_data.worker_prefer,
            important_for_support: get_job_data.important_for_support,
            status: get_job_data.status,
            participants: globalFunc.DataValid(get_job_data.participants) ? get_job_data.participants : [],
          }

          this.selectParticipantList = globalFunc.DataValid(get_job_data.participants) ? get_job_data.participants : []

          this.service_model.social_support = get_job_data.ssda_items
          if (get_job_data.ssda_items.length > 0) {
            this.service_collapse_list.social_support = false
          }
          this.service_model.personal_care = get_job_data.personal_care_items
          if (get_job_data.personal_care_items.length > 0) {
            this.service_collapse_list.personal_care = false
          }
          this.service_model.nursing = get_job_data.nursing_care_item
          if (get_job_data.nursing_care_item.length > 0) {
            this.service_collapse_list.nursing_service = false
          }
          this.service_model.allied = get_job_data.allied_type
          if (get_job_data.allied_type.length > 0) {
            this.service_collapse_list.allied_health = false
          }

          this.privateChecked()

          let date = get_job_data.working_date.split(' ~ ')
          this.job_start_date = date[0]
          this.job_end_date = date[1]
        }
      } catch (error) {
        this.$func.log(error)
        if (Array.isArray(error)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('navigation.job_post')} ${this.$t('error.does_not_exist')}`,
            type: 'error',
            refresh: false,
            redirect: 'JobPost',
          })
        }
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    async getUserData(id, source, selectParticipants = []) {
      try {
        let params = new URLSearchParams()
        params.set('get_user_by_id', JSON.stringify({ id: id }))
        let get_user_data = await getData(params)
        this.$func.log('-----Get User Data-----')
        this.$func.log(get_user_data)

        if (!Array.isArray(get_user_data)) {
          if (source == 'customer') {
            this.customer_data = {
              id: get_user_data.id,
              first_name: get_user_data.first_name,
              last_name: get_user_data.last_name,
            }

            let participants = get_user_data.participants

            let selectParticipantsId = selectParticipants.map(obj => {
              return obj.id
            })

            this.$func.log(selectParticipantsId)

            let temp = []
            for (let i = 0; i < participants.length; i++) {
              if (!selectParticipantsId.includes(participants[i].id)) {
                temp.push({
                  'item-text': `${participants[i].first_name} ${participants[i].last_name} (ID: ${participants[i].id})`,
                  'item-value': participants[i].id,
                })
              }
            }

            this.customer_data.participants = participants
            this.participantList = temp
          } else if (source == 'agreement') {
            this.agreement_data.worker_id = get_user_data.id
            this.agreement_data.first_name = get_user_data.first_name
            this.agreement_data.last_name = get_user_data.last_name
          }
        }
      } catch (error) {
        this.$func.log(error)
      }
    },
    async getPaymentData() {
      try {
        let params = new URLSearchParams()
        params.set('cms_get_payment', JSON.stringify({ job_id: parseInt(this.$route.params.id, 10) }))
        let get_payment_data = await getData(params)
        this.$func.log('-----Get Payment Data-----')
        this.$func.log(get_payment_data)

        if (!Array.isArray(get_payment_data)) {
          let payment = get_payment_data.data[0]
          this.show.payment = true

          this.getAgreementData(payment.agreement_id)
          this.getTimesheetData(payment.agreement_id)

          this.payment_data.id = payment.id
          this.payment_data.payment_method = payment.payment_method
          this.payment_data.payment_method_text =
            payment.payment_method == 'offline' ? this.$t('job_post.offline') : this.$t('job_post.credit_card')
          this.payment_data.status = payment.status
          this.payment_data.backup_status = payment.status

          if (payment.payment_method == 'offline') {
            this.payment_data.file = payment.payment_data.file
          }
        } else {
          this.show.payment = false
        }
      } catch (error) {
        this.$func.log(error)
        this.show.payment = false
      }
    },
    async getAgreementData(agreement_id) {
      try {
        this.agreed_rate_table.load_table = true
        let params = new URLSearchParams()
        params.set('get_agreement_by_id', JSON.stringify({ id: agreement_id }))
        let get_agreement_data = await getData(params)
        this.$func.log('-----Get Agreement Data-----')
        this.$func.log(get_agreement_data)

        if (!Array.isArray(get_agreement_data)) {
          this.show.agreement = true

          this.getUserData(get_agreement_data.worker_id, 'agreement')
          this.agreement_data.id = agreement_id
          this.agreement_data.create_date = globalFunc.getDateStr(get_agreement_data.create_date)
          this.agreement_data.service_description = get_agreement_data.service_description
          this.agreement_data.total = globalFunc.formatMoney(get_agreement_data.total)
          this.agreement_data.status = get_agreement_data.status

          let rates = get_agreement_data.rates
          this.agreed_rate_table.total_items = rates.length
          let temp = []

          for (let i = 0; i < rates.length; i++) {
            temp.push({
              id: rates[i].id,
              hourly_flat: rates[i].flat_rate,
              working_dates: rates[i].dates,
              start_time: rates[i].start_time,
              end_time: rates[i].end_time,
              rate: rates[i].rate,
            })
          }

          this.store_agreed_rate_table = temp

          this.agreed_rate_table_list = this.updateTableData(
            this.store_agreed_rate_table,
            0,
            this.agreed_rate_table.tablePageLimit,
          )
        } else {
          this.show.agreement = false
        }
      } catch (error) {
        this.$func.log(error)
        this.show.agreement = false
      } finally {
        this.agreed_rate_table.load_table = false
      }
    },
    async getTimesheetData(agreement_id) {
      try {
        this.timesheet_rate_table.load_table = true
        let params = new URLSearchParams()
        params.set('cms_get_time_sheet', JSON.stringify({ agreement_id: agreement_id }))
        let get_timesheet_data = await getData(params)
        this.$func.log('-----Get Timesheet Data-----')
        this.$func.log(get_timesheet_data)

        if (!Array.isArray(get_timesheet_data)) {
          let timesheet = get_timesheet_data.data[0]
          this.show.timesheet = true

          this.timesheet_data.id = timesheet.id
          this.payroll_paid_date = timesheet.payroll_paid_date
          this.timesheet_data.status = timesheet.status
          if (timesheet.status === 'approved') {
            this.agreement_data.status = 'timesheetCompleted'
          }
          this.timesheet_data.shift_note = timesheet.shift_note

          let rates = timesheet.rates
          this.timesheet_rate_table.total_items = rates.length
          let temp = []

          for (let i = 0; i < rates.length; i++) {
            temp.push({
              id: rates[i].id,
              hourly_flat: rates[i].flat_rate,
              working_dates: rates[i].dates,
              start_time: rates[i].start_time,
              end_time: rates[i].end_time,
              rate: rates[i].rate,
            })
          }

          this.store_timesheet_rate_table = temp

          this.timesheet_rate_table_list = this.updateTableData(
            this.store_timesheet_rate_table,
            0,
            this.timesheet_rate_table.tablePageLimit,
          )
        } else {
          this.show.timesheet = false
        }
      } catch (error) {
        this.$func.log(error)
        this.show.timesheet = false
      } finally {
        this.timesheet_rate_table.load_table = false
      }
    },
    privateChecked() {
      if (this.job_data.private) {
        this.job_data.job_type_disabled = true
        this.job_data.job_type = 'standard'
      } else {
        this.job_data.job_type_disabled = false
      }
    },
    viewRecord(fileName) {
      if (globalFunc.DataValid(fileName)) {
        let link = MEDIA_PATH + fileName
        window.open(link, '_blank')
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.file_not_found'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    updateTableData(arr, start, end) {
      return arr.slice(start, end)
    },
    onAgreedRateTableChange(options) {
      if (options.itemsPerPage !== this.agreed_rate_table.tablePageLimit) {
        this.agreed_rate_table.tablePage = 1 // reset to first page if view options changed
      } else {
        this.agreed_rate_table.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.agreed_rate_table.tablePageLimit = options.itemsPerPage
      this.agreed_rate_table_list = this.updateTableData(
        this.store_agreed_rate_table,
        (this.agreed_rate_table.tablePage - 1) * this.agreed_rate_table.tablePageLimit,
        this.agreed_rate_table.tablePageLimit * this.agreed_rate_table.tablePage,
      )
    },
    onTimesheetRateTableChange(options) {
      if (options.itemsPerPage !== this.timesheet_rate_table.tablePageLimit) {
        this.timesheet_rate_table.tablePage = 1 // reset to first page if view options changed
      } else {
        this.timesheet_rate_table.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.timesheet_rate_table.tablePageLimit = options.itemsPerPage
      this.timesheet_rate_table_list = this.updateTableData(
        this.store_timesheet_rate_table,
        (this.timesheet_rate_table.tablePage - 1) * this.timesheet_rate_table.tablePageLimit,
        this.timesheet_rate_table.tablePageLimit * this.timesheet_rate_table.tablePage,
      )
    },
    addParticipants() {
      if (globalFunc.DataValid(this.selectParticipantId)) {
        this.selectParticipantError = false

        let filterItem = this.customer_data.participants.filter(obj => {
          return obj.id == this.selectParticipantId
        })

        if (filterItem.length > 0) {
          this.selectParticipantList.push(filterItem[0])

          let index = this.participantList.findIndex(obj => {
            return obj['item-value'] == this.selectParticipantId
          })

          if (index != -1) {
            this.participantList.splice(index, 1)
          }
        }

        this.selectParticipantId = ''
      } else {
        this.selectParticipantError = true
      }
    },
    removeParticipants(index) {
      let id = this.selectParticipantList[index].id
      this.$func.log(this.selectParticipantList[index])
      this.selectParticipantList.splice(index, 1)

      // TODO: need to confirm

      let searchExist = this.customer_data.participants.findIndex(obj => {
        return obj.id == id
      })
      if (searchExist != -1) {
        let item = this.customer_data.participants[searchExist]
        this.participantList.push({
          'item-text': `${item.first_name} ${item.last_name} (ID: ${item.id})`,
          'item-value': item.id,
        })
      }

      this.selectParticipantId = ''
      this.selectParticipantError = false
    },
    async saveJob() {
      let form_validate = this.$refs.job_form.validate()

      let validate = true

      if (globalFunc.DataValid(this.job_start_date) && globalFunc.DataValid(this.job_end_date)) {
        if (new Date(this.job_start_date) > new Date(this.job_end_date)) {
          this.date_error = this.$t('error.compare_date')
          validate = false
        } else {
          this.date_error = ''
        }
      }

      if (this.selectParticipantList.length <= 0) {
        validate = false
        this.selectParticipantError = true
      } else {
        this.selectParticipantError = false
      }

      if (!form_validate || !validate) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('error.fill_all_required_field'),
          type: 'error',
          refresh: false,
          redirect: '',
        })

        return
      }

      try {
        this.submitLoading = true
        let send_data = {
          id: parseInt(this.$route.params.id, 10),
        }

        send_data.job_title = this.job_data.job_title
        send_data.job_type = this.job_data.job_type
        send_data.private = this.job_data.private
        send_data.location = this.job_data.location
        send_data.time_string = this.job_data.time
        send_data.estimated_hours = this.job_data.estimated_hours
        send_data.support_person_do = this.job_data.support_person_do
        send_data.gender_prefer = this.job_data.gender_prefer
        send_data.worker_prefer = this.job_data.worker_prefer
        send_data.important_for_support = this.job_data.important_for_support
        send_data.status = this.job_data.status
        send_data.ssda_items = this.service_model.social_support
        send_data.personal_care_items = this.service_model.personal_care
        send_data.nursing_care_item = this.service_model.nursing
        send_data.allied_type = this.service_model.allied
        send_data.participants = this.selectParticipantList

        let params = new URLSearchParams()
        params.set('update_job', JSON.stringify(send_data))
        let result = await postData(params)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_success'),
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_fail'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    async savePayment() {
      try {
        this.submitLoading = true
        let send_data = {
          id: this.payment_data.id,
        }

        send_data.status = this.payment_data.status

        let params = new URLSearchParams()
        params.set('update_payment', JSON.stringify(send_data))
        let result = await postData(params)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_success'),
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_fail'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    async saveAgreement() {
      let form_validate = this.$refs.agreement_form.validate()

      if (!form_validate) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('error.fill_all_required_field'),
          type: 'error',
          refresh: false,
          redirect: '',
        })

        return
      }

      try {
        this.submitLoading = true
        let send_data = {
          id: this.agreement_data.id,
        }

        send_data.service_description = this.agreement_data.service_description
        if (this.agreement_data.status !== 'timesheetCompleted') {
          send_data.status = this.agreement_data.status
        }

        let params = new URLSearchParams()
        params.set('update_agreement', JSON.stringify(send_data))
        let result = await postData(params)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_success'),
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_fail'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    async saveTimesheet() {
      try {
        this.submitLoading = true
        let send_data = {
          id: this.timesheet_data.id,
        }

        send_data.payroll_paid_date = this.payroll_paid_date
        send_data.status = this.timesheet_data.status
        send_data.shift_note = this.timesheet_data.shift_note

        let params = new URLSearchParams()
        params.set('update_time_sheet', JSON.stringify(send_data))
        let result = await postData(params)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_success'),
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_fail'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.$store.dispatch('toggleLoadingPage', true)
      this.initializeStatus()
      this.initializeServiceField()
      this.initializeHeader()
      this.initializeJobData()
      this.getPaymentData()
    }
  },
}
</script>

<style lang="scss" scoped>
.expansion-card {
  &:hover {
    cursor: pointer;
    background-color: rgb(240, 239, 239);
    transition: 0.3s;
  }
}
</style>